import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import css from './modal.module.scss';
import CloseModalBtn from '../../../../components/ui/modals/closeModalBtn/CloseModalBtn';
import { ReactComponent as GoBackIcon } from '../../../../shared/images/collection/go-back-gray-arrow.svg';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { getOnCloseAiModalFunc } from '../../model/slice/aiSlice';
import { useModal } from '../../../../hooks/ai/useAiModal';
import {
	getDisableKeyObserverFn,
	getEnableKeyObserverFn,
} from '../../../../redux/reducers/modals';
import { RootState } from '../../../../redux/reducers/reducer';

interface Props {
	active: boolean,
	closeModal: (active: boolean) => void,
	onGoBack: () => void,
	styles?: string,
	children: React.ReactNode,
	modalInModalActive?: boolean,
	title: string,
}

const portal = document.getElementById('portal');

/** Обёртка с порталом для модальных окон AI */
export const ModalWrapper: FC<Props> = ({
	active,
	closeModal,
	title,
	styles,
	children,
	modalInModalActive,
	onGoBack,
}) => {
	const {
		modalRef,
	} = useModal(closeModal, active, modalInModalActive);

	const disableFunction = useAppSelector((state: RootState) => getDisableKeyObserverFn(state));
	const enableFunction = useAppSelector(state => getEnableKeyObserverFn(state));

	useEffect(() => {
		if (active) {
			if (disableFunction) {
				disableFunction();
			}
		} else if (!active) {
			if (enableFunction) {
				enableFunction();
			}
		}
	}, [active]);

	const onCloseModal = useAppSelector(state => getOnCloseAiModalFunc(state));

	const onClose = () => {
		closeModal(false);
		if (onCloseModal !== null) onCloseModal();
	};

	return (
		<>
			{
				ReactDOM.createPortal(
					<CSSTransition
						in={active}
						timeout={100}
						nodeRef={modalRef}
						classNames={{
							enter: css.modal_enter,
							enterActive: css.modal_enter_active,
							exit: css.modal_exit,
							exitActive: css.modal_exit_active,
						}}
						unmountOnExit
					>
						<div
							className={css.modal}
							ref={modalRef}
							role="presentation"
						>
							<div
								className={cx(css.content_wrapper, styles)}

							>
								<div className={css.header}>
									<GoBackIcon className={css.backBtn} onClick={onGoBack} />
									<h3>{title}</h3>
									<CloseModalBtn onClose={onClose} className={css.closeBtn} />
								</div>
								{children}
							</div>
						</div>
					</CSSTransition>,
					portal as Element,
				)
			}
		</>
	);
};
