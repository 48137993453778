import IComponent from '../../components/IComponent';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import GraphicType from '../../graphic/GraphicType';
import IGraphic from '../../graphic/IGraphic';
import { AnyGraphicStructure, LayerSequences } from '../../Types';
import ILayerFoundResult from './ILayerFoundResult';

/**
 * Представляет собой хранилище для последовательности слоев график.
 */
class LayerRepository {
	private repository: IGraphic[][];

	private isEnableDebug: boolean;

	constructor() {
		this.repository = [];
		this.isEnableDebug = false;
	}

	/**
	 * Загружает в структуру последовательность слоев.
	 * @param components Компоненты.
	 * @param graphicStructures Источник порядка слоев.
	 */
	public load = (components: IComponent[], graphicStructures: AnyGraphicStructure[]) => {
		this.repository.length = 0;
		components.forEach((component) => {
			const graphics = component.getGraphics();
			const { offset } = component.getStructure();

			if (offset === null) {
				throw new ManipulatorError('offset is null');
			}

			graphics.forEach(graphic => {
				const structure = graphic.getStructure();
				if (structure.frame === null) {
					throw new ManipulatorError('frame is null');
				}
				const pageNumber = this.getGraphicPageNumber(graphic);

				const graphicID = graphic.getID();
				const originalGraphicStructure = graphicStructures.find(structure => structure.id === graphicID);
				if (originalGraphicStructure === undefined) {
					throw new ManipulatorError('original graphic structure not found');
				}
				const layer = originalGraphicStructure.frame?.layer;
				if (layer === undefined || layer === null) {
					throw new ManipulatorError('layer is undefined');
				}

				if (this.repository[pageNumber] === undefined) {
					this.repository[pageNumber] = [];
				}
				if (this.repository[pageNumber][layer] !== undefined) {
					// throw new ManipulatorError(`failed to load into the structure because the space is
					// full (page=${pageNumber}, layer=${layer})`);
					console.warn(`[${pageNumber}:${layer}] ${graphicID}`);
				}
				this.repository[pageNumber][layer] = graphic;
			});
		});

		// console.log(this.repository.map(pageLayers => {
		// 	const s = [];
		// 	for (let i = 0; i < pageLayers.length; i++) {
		// 		s.push(pageLayers[i] === undefined ? undefined : pageLayers[i].getID());
		// 	}
		// 	return s;
		// }));

		const a: string[][][] = [];

		components.forEach(component => {
			component.getGraphics().map(graphic => {
				const pageNumber = this.getGraphicPageNumber(graphic);
				if (a[pageNumber] === undefined) {
					a[pageNumber] = [];
				}
				const { layer } = graphic.getFrameConfiguration();

				if (a[pageNumber][layer] === undefined) {
					a[pageNumber][layer] = [];
				}
				a[pageNumber][layer].push(graphic.getID());
			});
		});

		console.log(a);

		this.printDebug('load');

		this.sync();
	};

	/**
	 * Синхронизирует значения в графике со значениями в структуре.
	 */
	public sync = () => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];
			for (let j = 0; j < sequence.length; j++) {
				sequence[j].setFrameConfiguration((prev) => ({
					...prev,
					layer: j,
				}));
			}
		}
	};

	/**
	 * Устанавливает заданную последовательность слоев в манипулятор.
	 * @exception Если графика, идентификатор которой находится в последовательности, не найдена в дереве компонентов.
	 * @exception Если идентификатор существующей графики не находится в пришедшей последовательности.
	 * @param layerSequences Последовательность слоев.
	 * @param components Компоненты, к которым будет применена последовательность.
	 */
	public setSequences = (components: IComponent[], layerSequences: LayerSequences) => {
		const graphics: IGraphic[] = components.map(component => component.getGraphics()).flat();
		const graphicSequences: IGraphic[][] = [];

		let registeredGraphicCount = 0;
		layerSequences.forEach(sequence => {
			const graphicSequence: IGraphic[] = [];

			sequence.forEach(graphicID => {
				const graphic = graphics.find(graphic => graphic.getID() === graphicID);
				if (graphic === undefined) {
					throw new ManipulatorError('graphic not found');
				}

				registeredGraphicCount++;
				graphicSequence.push(graphic);
			});

			graphicSequences.push(graphicSequence);
		});

		if (graphics.length !== registeredGraphicCount) {
			throw new ManipulatorError('not all graphics are registered');
		}

		this.repository.length = 0;
		graphicSequences.forEach((sequence) => {
			sequence.forEach((graphic, graphicIndex) => {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					layer: graphicIndex,
				}));
			});
			this.repository.push(sequence);
		});

		const a: string[][][] = [];

		components.forEach(component => {
			component.getGraphics().map(graphic => {
				const pageNumber = this.getGraphicPageNumber(graphic);
				if (a[pageNumber] === undefined) {
					a[pageNumber] = [];
				}
				const { layer } = graphic.getFrameConfiguration();

				if (a[pageNumber][layer] === undefined) {
					a[pageNumber][layer] = [];
				}
				a[pageNumber][layer].push(graphic.getID());
			});
		});
	};

	/**
	 * Удаляет слой из последовательности.
	 * @param graphic - удаляемая графика.
	 */
	public removeLayer = (graphic: IGraphic) => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];
			for (let j = 0; j < sequence.length; j++) {
				const currentGraphic = sequence[j];
				if (currentGraphic === graphic) {
					sequence.splice(j, 1);
					if (this.repository[i].length === 0) {
						this.repository.splice(i, 1);
					}
					this.printDebug('removeLayer', graphic);
					return;
				}
			}
		}

		throw new ManipulatorError('removed graphic not found');
	};

	/**
	 * Добавляет графику в последний слой страницы.
	 * @param graphic - добавляемая графика
	 * @param pageNumber - номер страницы, на которую будет добавлена графика
	 */
	public appendLayer = (graphic: IGraphic, pageNumber: number): number => {
		if (this.repository[pageNumber] === undefined) {
			this.repository[pageNumber] = [];
		}
		if (this.repository[pageNumber][this.repository[pageNumber].length - 1] === graphic) {
			this.printDebug('appendLayer', graphic);
			return this.repository[pageNumber].length - 1;
		}

		const createdIndex = this.repository[pageNumber].length;
		this.repository[pageNumber][createdIndex] = graphic;

		this.printDebug('appendLayer', graphic);

		return this.repository[pageNumber].length - 1;
	};

	/**
	 * Добавляет новую графику в последовательность сразу после другой графики.
	 * @param graphic - добавляемая графика
	 * @param afterLayer - графика, после которой нужно добавить новую графику
	 */
	public appendLayerAfter = (graphic: IGraphic, afterLayer: IGraphic) => {
		if (graphic.type === GraphicType.PAGE) {
			const newLayer = afterLayer.getOffset() + 1;

			if (this.repository[newLayer] !== undefined) {
				const sequenceCount = this.repository.length;
				for (let i = newLayer; i < sequenceCount; i++) {
					this.repository[i + 1] = this.repository[i];
				}
			}
			this.repository[newLayer] = [afterLayer];
			this.printDebug('appendLayerAfter', graphic);

			return;
		}

		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];
			const sequenceLength = sequence.length;

			for (let j = 0; j < sequenceLength; j++) {
				const currentGraphic = sequence[j];

				if (currentGraphic === afterLayer) {
					if (sequence[j + 1] !== undefined) {
						for (let k = sequenceLength - 1; k > j; k--) {
							sequence[k + 1] = sequence[k];
						}
					}
					sequence[j + 1] = graphic;
					this.printDebug('appendLayerAfter', graphic);

					return;
				}
			}
		}

		throw new ManipulatorError('graphic is after not found');
	};

	/**
	 * Перемещает графику на слой ниже.
	 * @param graphic - перемещаемая графика
	 */
	public moveBack = (graphic: IGraphic) => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				const currentGraphic = sequence[j];
				if (currentGraphic === graphic) {
					if (j === 0) {
						this.printDebug('moveBack', graphic);
						return;
					}

					const prevGraphic = this.getPrevGraphic(graphic);
					if (prevGraphic === null) throw new ManipulatorError('Previous graphic is null');

					/* Обработать случай: графика передвигается внутри группы - здесь мы не должны выйти за пределы
					группы (когда предыдущая графика это групповая графика и передвигаемая графика это
					графика в этой группе */
					if (prevGraphic.type === GraphicType.GROUP && graphic.getParentGroupGraphic() === prevGraphic) {
						return;
					}

					/* Обработать случай: если предыдущая графика это графика в группе, а передвигаемая графика не
					входит в эту группу */
					const prevGraphicParent = prevGraphic.getParentGroupGraphic();
					const graphicParent = graphic.getParentGroupGraphic();
					if (prevGraphicParent && !graphicParent) {
						this.moveGraphicBefore(prevGraphicParent, graphic);
						return;
					}

					// 2. Перемещаемая графика - это групповая графика. В этом случае нужно предшествующую графику
					// поставить после последней графики в группе
					if (graphic.type === GraphicType.GROUP) {
						// Найти графику, предыдущую от групповой графики
						const beforeGroupGraphic = this.getPrevGraphic(graphic);
						if (beforeGroupGraphic === null) return;

						this.moveGroupGraphicBefore(beforeGroupGraphic, graphic);
						this.sync();
						return;
					}

					sequence[j] = sequence[j - 1];
					sequence[j - 1] = graphic;
					this.printDebug('moveBack', graphic);

					this.sync();
					return;
				}
			}
		}

		throw new ManipulatorError('graphic not found');
	};

	/**
	 * Перемещает графику на слой выше.
	 * @param graphic - перемещаемая графика
	 */
	public moveForward = (graphic: IGraphic) => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				const currentGraphic = sequence[j];
				if (currentGraphic === graphic) {
					if (j === sequence.length - 1) {
						this.printDebug('moveForward', graphic);

						return;
					}

					const nextGraphic = this.getNextGraphic(graphic);
					if (nextGraphic === null) return;

					/* Обработать случаи когда перемещаемая графика - это графика внутри группы */
					// 1. Если следующая графика это не графика внутри группы
					if (!nextGraphic.getParentGroupGraphic() && graphic.getParentGroupGraphic()) {
						return;
					}

					/* Обработать случаи когда перемещаемая графика - это НЕ графика внутри группе */
					// 1. следующая графика это групповая графика
					if (nextGraphic.type === GraphicType.GROUP) {
						const lastGraphic = this.getLastGraphicInGroup(nextGraphic);
						this.moveGraphicAfter(lastGraphic, graphic);
					}

					// 2. Перемещаемая графика - это групповая графика
					if (graphic.type === GraphicType.GROUP) {
						// найти последнюю графику в группе
						const lastGraphicInGroup = this.getLastGraphicInGroup(graphic);

						// Найти следующую графику за последней графикой в группе
						const afterGroupGraphic = this.getNextGraphic(lastGraphicInGroup);
						if (afterGroupGraphic === null) return;

						this.moveGroupGraphicAfter(afterGroupGraphic, graphic);
						this.sync();
						return;
					}

					sequence[j] = sequence[j + 1];
					sequence[j + 1] = graphic;

					this.printDebug('moveForward', graphic);

					this.sync();
					return;
				}
			}
		}

		throw new ManipulatorError('graphic not found');
	};

	/**
	 * Перемещает графику на дальний слой.
	 * @param graphic - перемещаемая графика
	 */
	public moveToBackground = (graphic: IGraphic) => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 1; j < sequence.length; j++) {
				const currentGraphic = sequence[j];
				if (currentGraphic === graphic) {
					if (j === 1) {
						this.printDebug('moveToBackground', graphic);
						return;
					}

					// Если graphic - это графика в группе, то мы не должны выйти за пределы группы
					const groupParentGraphic = graphic.getParentGroupGraphic();
					if (groupParentGraphic) {
						this.moveGraphicAfter(groupParentGraphic, graphic);
						this.sync();
						return;
					}

					// Если graphic - это групповая графика, мы должны переместить все внутренние графики группы
					if (graphic.type === GraphicType.GROUP) {
						this.moveGroupGraphicBefore(sequence[1], graphic);
						return;
					}

					for (let k = j; k > 1; k--) {
						sequence[k] = sequence[k - 1];
					}
					sequence[1] = graphic;
					this.printDebug('moveToBackground', graphic);

					this.sync();
					return;
				}
			}
		}

		throw new ManipulatorError('graphic not found');
	};

	/**
	 * Перемещает графика на самый ближний слой.
	 * @param graphic - перемещаемая графика
	 */
	public moveToForeground = (graphic: IGraphic) => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				const currentGraphic = sequence[j];
				if (currentGraphic === graphic) {
					if (j === sequence.length - 1) {
						this.printDebug('moveToForeground', graphic);

						return;
					}

					// Если graphic - это графика в группе, то мы не должны выйти за пределы группы
					const groupGraphic = graphic.getParentGroupGraphic();
					if (groupGraphic) {
						const lastGraphicInGroup = this.getLastGraphicInGroup(groupGraphic);
						this.moveGraphicAfter(lastGraphicInGroup, graphic);
						this.sync();
						return;
					}

					// Если graphic - это групповая графика, мы должны переместить все внутренние графики группы
					if (graphic.type === GraphicType.GROUP) {
						this.moveGroupGraphicAfter(sequence[sequence.length - 1], graphic);
						this.sync();
						return;
					}

					for (let k = j; k < sequence.length; k++) {
						sequence[k] = sequence[k + 1];
					}
					sequence[sequence.length - 1] = graphic;
					this.printDebug('moveToForeground', graphic);

					this.sync();
					return;
				}
			}
		}

		throw new ManipulatorError('graphic not found');
	};

	public getLastPageGraphic = (pageIndex: number): IGraphic => {
		if (this.repository[pageIndex] === undefined) {
			throw new ManipulatorError(`layers sequence not found from ${pageIndex} number page`);
		}
		return this.repository[pageIndex][this.repository[pageIndex].length - 1];
	};

	/**
	 * Возвращает предыдущую графику согласно слою. В случае, если эта графика первая - null.
	 * @param graphic - графика, от которой начинается отсчет.
	 */
	public getPrevGraphic = (graphic: IGraphic): IGraphic | null => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				if (sequence[j] === graphic) {
					if (j === 0) {
						throw new ManipulatorError('find graphic is page component');
					}
					if (sequence[j - 1] === undefined) {
						throw new ManipulatorError('prev graphic not found');
					}
					this.printDebug('getPrevGraphic', graphic);
					return sequence[j - 1];
				}
			}
		}
		throw new ManipulatorError('layer repository does not contain the graphic');
	};

	/**
	 * Возвращает следующую графику согласно слою. В случае, если graphic - это последняя графика, то вернет null.
	 * @param graphic - графика, от которой начинается отсчет.
	 */
	public getNextGraphic = (graphic: IGraphic): IGraphic | null => {
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				if (sequence[j] === graphic) {
					if (j === 0) {
						throw new ManipulatorError('find graphic is page component');
					}
					if (j === sequence.length - 1) {
						this.printDebug('moveToForeground', graphic);

						return null;
					}
					if (sequence[j + 1] === undefined) {
						throw new ManipulatorError('prev next graphic not found');
					}
					this.printDebug('moveToForeground', graphic);

					return sequence[j + 1];
				}
			}
		}
		throw new ManipulatorError('layer repository does not contain the graphic');
	};

	/**
	 * Перемещает графику на слой после `prevGraphic`.
	 * @param prevGraphic - графика, после которой стоит вставить `moveGraphic`.
	 * @param graphic - вставляемая графика.
	 */
	public moveGraphicAfter = (prevGraphic: IGraphic | null, graphic: IGraphic) => {
		if (prevGraphic === null) {
			for (let i = 0; i < this.repository.length; i++) {
				const sequence = this.repository[i];
				if (sequence[1] === graphic) {
					return;
				}
				const index = sequence.indexOf(graphic);
				if (index !== -1) {
					sequence.splice(index, 1);
				}
				sequence.splice(1, 0, graphic);
			}
			this.printDebug('moveGraphicAfter', graphic);

			return;
		}

		if (prevGraphic === graphic) {
			throw new ManipulatorError('same graphics');
		}

		const graphicPosition = this.foundLayer(graphic);
		let prevGraphicPosition = this.foundLayer(prevGraphic);

		if (!graphicPosition.isFound) {
			throw new ManipulatorError('graphic not connected', { graphic: graphic.getID(), graphicPosition });
		}
		if (!prevGraphicPosition.isFound) {
			throw new ManipulatorError('graphic not connected', { graphic: prevGraphic.getID(), prevGraphicPosition });
		}

		if (
			graphicPosition.pageNumber === prevGraphicPosition.pageNumber
			&& prevGraphicPosition.index + 1 === graphicPosition.index
		) {
			this.printDebug('moveGraphicAfter', graphic);

			return;
		}

		this.repository[graphicPosition.pageNumber].splice(graphicPosition.index, 1);

		if (graphicPosition.pageNumber === prevGraphicPosition.pageNumber) {
			prevGraphicPosition = this.foundLayer(prevGraphic);
			if (!prevGraphicPosition.isFound) {
				throw new ManipulatorError(
					'graphic not connected',
					{ graphic: prevGraphic.getID(), prevGraphicPosition },
				);
			}
		}

		this.repository[prevGraphicPosition.pageNumber].splice(prevGraphicPosition.index + 1, 0, graphic);

		this.printDebug('moveGraphicAfter', graphic);

		this.sync();
	};

	/**
	 * Перемещает графику на слой перед `nextGraphic`.
	 * @param graphic - графика, после которой стоит вставить `graphic`.
	 * @param moveGraphic - вставляемая графика.
	 */
	public moveGraphicBefore = (graphic: IGraphic | null, moveGraphic: IGraphic) => {
		if (graphic === null) {
			for (let i = 0; i < this.repository.length; i++) {
				const sequence = this.repository[i];
				if (sequence[1] === moveGraphic) {
					return;
				}
				const index = sequence.indexOf(moveGraphic);
				if (index !== -1) {
					sequence.splice(index, 1);
				}
				sequence.splice(1, 0, moveGraphic);
			}
			this.printDebug('moveGraphicAfter', moveGraphic);

			return;
		}
		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];
			const graphicIndex = sequence.indexOf(graphic);
			const moveGraphicIndex = sequence.indexOf(moveGraphic);
			if (graphicIndex === -1 || (graphicIndex === -1 && moveGraphicIndex === -1)) {
				throw new ManipulatorError('graphic or prevGraphic not found');
			}
			if (moveGraphicIndex !== -1) {
				// Обработать случай когда ставим графику перед первой графикой на странице (с индексом 1)
				if (graphicIndex === 1) {
					sequence.splice(graphicIndex, 0, moveGraphic);
					sequence.splice(moveGraphicIndex + 1, 1);
					this.sync();
					return;
				}
				if (moveGraphicIndex === graphicIndex - 1) {
					return;
				}
				sequence.splice(moveGraphicIndex, 1);
			}

			sequence.splice(graphicIndex - 1, 0, moveGraphic);
		}

		this.printDebug('moveGraphicAfter', moveGraphic);

		this.sync();
	};

	/**
	 * Перемещает графику на слой перед `nextGraphic`.
	 * @param graphic - графика, после которой стоит вставить `graphic`.
	 * @param moveGroupGraphic - вставляемая графика.
	 */
	public moveGroupGraphicBefore = (graphic: IGraphic, moveGroupGraphic: IGraphic) => {
		if (moveGroupGraphic.type !== GraphicType.GROUP) {
			throw new ManipulatorError('moveGroupGraphic is not GraphicType.GROUP');
		}

		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				if (sequence[j] === moveGroupGraphic) {
					if (j === 0) {
						throw new ManipulatorError('find graphic is page component');
					}

					const sequence = this.repository[i];
					const graphicIndex = sequence.indexOf(graphic);
					const moveGraphicIndex = sequence.indexOf(moveGroupGraphic);

					if (graphicIndex === -1 || (graphicIndex === -1 && moveGraphicIndex === -1)) {
						throw new ManipulatorError('graphic or prevGraphic not found');
					}

					const groupGraphic = [moveGroupGraphic];

					// Записать массив групповой графики
					let k = j + 1;
					while (
						k < sequence.length
						&& sequence[k].getParentGroupGraphic()
					) {
						groupGraphic.push(sequence[k]);
						k++;
					}

					if (moveGraphicIndex !== -1) {
						/* Если у групповой графики изначально слой больше, чем у графики, после которой её нужно
						вставить - значит, когда добавим групповую графику в первом splice, у нас индекс группы для
						последующего удаления сместится на величину группы */
						if (graphicIndex < moveGraphicIndex) {
							// Вставить группу на следующий слой за graphic
							for (let l = 0, t = graphicIndex; l < groupGraphic.length; l++, t++) {
								sequence.splice(t, 0, groupGraphic[l]);
							}
							sequence.splice(moveGraphicIndex + groupGraphic.length, groupGraphic.length);
							this.sync();
							return;
						}
						for (let l = 0, t = graphicIndex; l < groupGraphic.length; l++, t++) {
							sequence.splice(t, 0, groupGraphic[l]);
						}
						// Удалить с того же индекса
						sequence.splice(moveGraphicIndex, groupGraphic.length);
						this.sync();
						return;
					}
				}
			}
		}
	};

	/**
	 * Перемещает графику на слой после `graphic`.
	 * @param graphic - графика, после которой вставится `moveGroupGraphic`.
	 * @param moveGroupGraphic - вставляемая графика.
	 */
	public moveGroupGraphicAfter = (graphic: IGraphic, moveGroupGraphic: IGraphic) => {
		if (moveGroupGraphic.type !== GraphicType.GROUP) {
			throw new ManipulatorError('moveGroupGraphic is not GraphicType.GROUP');
		}

		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				if (sequence[j] === moveGroupGraphic) {
					if (j === 0) {
						throw new ManipulatorError('find graphic is page component');
					}

					const sequence = this.repository[i];
					const graphicIndex = sequence.indexOf(graphic);
					const moveGraphicIndex = sequence.indexOf(moveGroupGraphic);

					if (graphicIndex === -1 || (graphicIndex === -1 && moveGraphicIndex === -1)) {
						throw new ManipulatorError('graphic or prevGraphic not found');
					}

					if (moveGraphicIndex === sequence.length - 1) {
						return;
					}

					const groupGraphic = [moveGroupGraphic];

					// Записать массив групповой графики
					let k = j + 1;
					while (
						k < sequence.length
						&& sequence[k].getParentGroupGraphic()
					) {
						groupGraphic.push(sequence[k]);
						k++;
					}

					if (moveGraphicIndex !== -1) {
						/* Если у групповой графики изначально слой больше, чем у графики, после которой её нужно
						вставить - значит, когда добавим групповую графику в первом splice, у нас индекс группы для
						последующего удаления сместится на величину группы */
						if (graphicIndex < moveGraphicIndex) {
							// Вставить группу на следующий слой за graphic
							for (let l = 0, t = graphicIndex; l < groupGraphic.length; l++, t++) {
								sequence.splice(t + 1, 0, groupGraphic[l]);
							}
							sequence.splice(moveGraphicIndex + groupGraphic.length, groupGraphic.length);
							this.sync();
							return;
						}
						for (let l = 0, t = graphicIndex; l < groupGraphic.length; l++, t++) {
							sequence.splice(t + 1, 0, groupGraphic[l]);
						}
						// Удалить с того же индекса
						sequence.splice(moveGraphicIndex, groupGraphic.length);
						this.sync();
						return;
					}
				}
			}
		}
	};

	/**
	 * Добавляет новую страницу в последовательность слоев.
	 * @param graphic Графика страницы.
	 * @param pageIndex Позиция страницы.
	 */
	public appendPage = (graphic: IGraphic, pageIndex: number) => {
		if (this.repository[pageIndex] === undefined) {
			this.repository[pageIndex] = [graphic];
		} else {
			const postPages = this.repository.slice(pageIndex);
			this.repository[pageIndex] = [graphic];
			this.repository = [...this.repository.slice(0, pageIndex + 1), ...postPages];
		}

		this.sync();
	};

	public reset = () => {
		this.repository = [];
	};

	/**
	 * Возвращает последовательность графики в виде списка ID.
	 */
	public getSequences = (): LayerSequences => this.repository.map(page => page.map(graphic => graphic.getID()));

	private printDebug = (funcName: string, graphic?: IGraphic) => {
		if (!this.isEnableDebug) {
			return;
		}
		console.log(`${funcName}(${graphic !== undefined ? `${graphic.getShortID()})` : ''}:`);
		this.debugRepositoryToConsole();
	};

	/**
	 * Возвращает графику внутри группы с самым большим номером слоя.
	 * @param groupGraphic - групповая графика.
	 */
	private getLastGraphicInGroup = (groupGraphic: IGraphic): IGraphic => {
		if (groupGraphic.type !== GraphicType.GROUP) {
			throw new ManipulatorError('groupGraphic is not GraphicType.GROUP');
		}

		for (let i = 0; i < this.repository.length; i++) {
			const sequence = this.repository[i];

			for (let j = 0; j < sequence.length; j++) {
				if (sequence[j] === groupGraphic) {
					if (j === 0) {
						throw new ManipulatorError('groupGraphic is page component');
					}
					if (sequence[j + 1] === undefined) {
						throw new ManipulatorError('next graphic not found');
					}
					let groupIndex = j;

					while (
						groupIndex + 1 < sequence.length
						&& this.repository[i][groupIndex + 1].getParentGroupGraphic()
					) {
						groupIndex++;
					}
					return sequence[groupIndex];
				}
			}
		}
		throw new ManipulatorError('layer repository does not contain the graphic');
	};

	private getGraphicPageNumber = (graphic: IGraphic): number => {
		let result = 0;
		// eslint-disable-next-line prefer-destructuring
		let parentComponent: IComponent | null = graphic.getParentComponent();
		const graphicOffset = graphic.getOffset();

		while (parentComponent !== null) {
			const { offset } = parentComponent.getStructure();
			if (offset === null) {
				break;
			}

			result += offset;
			parentComponent = parentComponent.getParentComponent();
		}
		return result + graphicOffset;
	};

	private foundLayer = (graphic: IGraphic): ILayerFoundResult => {
		for (let i = 0; i < this.repository.length; i++) {
			const index = this.repository[i].indexOf(graphic);
			if (index !== -1) {
				return {
					index,
					isFound: true,
					pageNumber: i,
				};
			}
		}
		return {
			index: -1,
			pageNumber: -1,
			isFound: false,
		};
	};

	private enableDebug = () => {
		this.isEnableDebug = true;
	};

	private debugRepositoryToConsole = () => {
		this.repository.forEach((pageSequence, index) => {
			console.log(`${index + 1} page -> ${pageSequence.map(graphic => graphic.getShortID()).join(', ')}`);
		});
	};
}

export default LayerRepository;
