import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './reducer';

interface IModalsState {
	/**	Чтобы протащить в React методы отключения/включения горячих клавиш конструктора,
	 * так как возникла проблема срабатывания при работе в модалках (большинство модалок
	 * на React) */
	enableKeyObserverFn: (() => void) | null,
	disableKeyObserverFn: (() => void) | null,
}

const initialState: IModalsState = {
	enableKeyObserverFn: null,
	disableKeyObserverFn: null,
};

export const modalsSlice = createSlice({
	name: 'modals',
	initialState,
	selectors: {
		getEnableKeyObserverFn: (state) => state.enableKeyObserverFn,
		getDisableKeyObserverFn: (state) => state.disableKeyObserverFn,
	},
	reducers: {
		setEnableKeyObserverFn: (state, action: PayloadAction<() => void>) => {
			state.enableKeyObserverFn = action.payload;
		},
		setDisableKeyObserverFn: (state, action: PayloadAction<() => void>) => {
			state.disableKeyObserverFn = action.payload;
		},
		enableKeyObserverFn: (state) => {
			setTimeout(() => {
				if (state.enableKeyObserverFn === null) {
					console.log('Enable key observer function was not passed');
					return;
				}
				state.enableKeyObserverFn();
			}, 0);
		},
		disableKeyObserverFn: (state) => {
			setTimeout(() => {
				if (state.disableKeyObserverFn === null) {
					console.log('disable Key Observer function was not passed');
					return;
				}
				state.disableKeyObserverFn();
			}, 0);
		},
	},
});

export const {
	setEnableKeyObserverFn,
	enableKeyObserverFn,
	setDisableKeyObserverFn,
	disableKeyObserverFn,
} = modalsSlice.actions;

export const {
	getEnableKeyObserverFn,
	getDisableKeyObserverFn,
} = modalsSlice.selectors;

export default modalsSlice.reducer;
