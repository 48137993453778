import GraphicComponent from '../GraphicComponent';
import SketchComponentType from '../SketchComponentType';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import { IFrameArea } from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';

/**
 * Компонент для отображения изображений.
 */
class PictureComponent extends GraphicComponent<null, PictureGraphic> {
	public readonly type: SketchComponentType = SketchComponentType.PICTURE;

	private isAdaptPictureAfterSetup: boolean;

	constructor() {
		super();
		this.isAdaptPictureAfterSetup = true;
	}

	/**
	 * Отменяет все изменения в режиме редактирования и возвращает в состояния до различных
	 * мутаций в режиме редактирования.
	 */
	public cancelChanges = () => {
		if (!this.isEnableEditMode()) {
			return;
		}

		const structure = this.getStructure();
		structure.graphics = this.getGraphicState();
		this.setStructure(_ => structure);
		this.disableEditMode();
		this.clearGraphicState();
	};

	/**
	 * Запускает процесс загрузки изображения во всей графике.
	 */
	public runLoadPicture = () => {
		const graphics = this.getGraphics() as PictureGraphic[];
		graphics.forEach(graphic => graphic.runLoadPicture());
	};

	/**
	 * Сбрасывает положение изображения относительно фрейма, максимально заполняет и центрирует.
	 */
	public resetPictureSize = () => {
		const graphics = this.getGraphics() as PictureGraphic[];
		graphics.forEach(graphic => graphic.resetPictureSize());
	};

	public enableAdaptPictureAfterSetup = () => {
		this.isAdaptPictureAfterSetup = true;
	};

	public disableAdaptPictureAfterSetup = () => {
		this.isAdaptPictureAfterSetup = false;
	};

	public hasAdaptPictureAfterSetup = (): boolean => this.isAdaptPictureAfterSetup;

	public setNewPicture = (pictureID: string) => {
		const graphics = this.getGraphics() as PictureGraphic[];
		graphics.forEach(graphic => {
			graphic.setNewPicture(pictureID);
		});
	};

	private getPictureArea = (): IFrameArea => {
		const graphics = this.getGraphics() as PictureGraphic[];
		const graphic = graphics[0];
		if (graphic === undefined) {
			throw new ManipulatorError('picture graphic nor found');
		}
		return graphic.getPictureArea();
	};

	public getTexture = (): null => null;

	public getUniqueTexture = (): null => null;

	public setTexture = (fn: (prev: null) => null): void => {
		// nothing
	};
}

export default PictureComponent;
