// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { imageToPDF, mergePDF } from 'pdf-actions';
import html2canvas from 'html2canvas';
import { AnyComponentStructure } from '../../Sketch/Types';
import OfferPrinter from '../SketchPrinter/OfferPrinter';

class SketchPDFGenerator extends OfferPrinter {
	private readonly BASE_CLASS = 'sketch-manipulator';
	private readonly ZOOMING_CLASS = 'sketch-pdf-generator';

	constructor(
		manipulatorContainer: HTMLDivElement,
		structure: AnyComponentStructure,
		name: string,
	) {
		super(manipulatorContainer, structure);
		this.setName(name);
		this.manipulatorElement.classList.add(this.BASE_CLASS);
		this.manipulatorElement.classList.add(this.ZOOMING_CLASS);
	}

	public savePDF = async () => {
		const pages = this.componentTree.getPages();

		const pageBlobPromises = pages.map(page => new Promise<Blob>(resolve => {
			html2canvas(page.getGraphicElement(), {
				scale: 2, // Настройка масштаба (1 для оригинального масштаба)
				logging: true, // Включить логирование в консоли для отладки
				useCORS: true, // Включить поддержку CORS для работы с внешними изображениями
			})
				.then(canvasElement => canvasElement.toBlob((blob) => {
					resolve(blob as Blob);
				}, 'image/jpeg', 1));
		}));

		const images = await Promise.all(pageBlobPromises);

		const imageBufferPromises = images.map(image => image.arrayBuffer());
		const imageBuffers = await Promise.all(imageBufferPromises);

		const listPromises = imageBuffers.map(buffer => imageToPDF(buffer));
		const lists = await Promise.all(listPromises);

		const mergedPDF = await mergePDF(lists);

		const bytes = await mergedPDF.save();
		const blob = new Blob([bytes], { type: 'application/pdf' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = this.getName();
		link.click();
	};
}

export default SketchPDFGenerator;
